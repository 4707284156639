import { FoodItem, FoodItemKey } from "client/jspPlatformExperiment";

/**
 * Get the food item option name shown in drop-down menu most of the time
 * @param food The food item key or food item object
 */
export const getFoodItemOptionName = (food: FoodItemKey | FoodItem): string =>
  `${food.food_name ?? ""} - ${food.id}`;

/**
 * Get the food item option maps to use in multiple components
 * @param foodItemOptions The array of food item or food item key objects
 */
export const getFoodItemOptionMaps = (
  foodItemOptions: Array<FoodItemKey | FoodItem>,
): {
  foodItemOptionNamesToIdsMap: Record<string, number>;
  idsToFoodItemOptionNamesMap: Map<number, string>;
  idsToFoodNamesMap: Map<number, string>;
} => {
  const foodItemOptionNamesToIdsMap: Record<string, number> = { "": 0 };
  const idsToFoodItemOptionNamesMap: Map<number, string> = new Map([[0, ""]]);
  const idsToFoodNamesMap: Map<number, string> = new Map([[0, ""]]);
  foodItemOptions.map((foodItemKey) => {
    const foodItemOptionName = getFoodItemOptionName(foodItemKey);
    foodItemOptionNamesToIdsMap[foodItemOptionName] = foodItemKey.id;
    idsToFoodItemOptionNamesMap.set(foodItemKey.id, foodItemOptionName);
    idsToFoodNamesMap.set(foodItemKey.id, foodItemKey.food_name ?? "");
    return foodItemOptionNamesToIdsMap;
  });
  return {
    foodItemOptionNamesToIdsMap,
    idsToFoodItemOptionNamesMap,
    idsToFoodNamesMap,
  };
};
