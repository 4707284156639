import React, { FC, useCallback, useEffect, useState } from "react";
import EditSection from "components/common/ui/EditSection";
import { Stack } from "@mui/material";
import { FoodItemKey } from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import PillButton from "components/common/ui/PillButton";
import Box from "@mui/material/Box";
import PortionOrderInputFormRow from "components/inventory/ui/purchasing/portionOrders/PortionOrderInputFormRow";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import dishPortionOrdersSelector from "store/selectors/order/dishPortionOrdersSelector";
import { actions } from "store/slices/order/dishPortionOrdersSlice";

interface PortionOrderInputFormProps {}

const PortionOrderInputForm: FC<PortionOrderInputFormProps> = () => {
  const [foodItemOptions, setFoodItemOptions] = useState<FoodItemKey[]>([]);
  const portionOrders = useAppSelector(
    dishPortionOrdersSelector.allDishPortionOrders,
  );
  const dispatch = useAppDispatch();

  /**
   * Use effect to load the food item options when first time loading the page
   */
  useEffect(() => {
    if (foodItemOptions.length === 0) {
      jspPlatformApi()
        .recipe.searchFoodRecipesFoodRecipeSearchGet({
          returnKeysOnly: true,
        })
        .then((results) => {
          setFoodItemOptions(
            results.data.map((recipeKey) => ({
              id: recipeKey.food_id,
              food_name: recipeKey.food_name,
            })),
          );
        })
        .catch(() => {
          setFoodItemOptions([]);
        });
    }
  }, [foodItemOptions.length]);

  /**
   * Add a new row to dish portion orders
   */
  const handleAppendEntity = useCallback(() => {
    dispatch(actions.appendDishPortionOrder());
  }, [dispatch]);

  return (
    <EditSection header="Required portion orders">
      <Stack spacing={1}>
        {Object.entries(portionOrders).map(([key]) => (
          <PortionOrderInputFormRow
            key={key}
            foodItemOptions={foodItemOptions}
            displayUUID={key}
          />
        ))}
        <Box sx={{ alignSelf: "center" }}>
          <PillButton text="Add portion order" onClick={handleAppendEntity} />
        </Box>
      </Stack>
    </EditSection>
  );
};

export default PortionOrderInputForm;
