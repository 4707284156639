import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuBasic } from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import Routes from "models/routes";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DataGridListBox from "components/common/ui/DataGridListBox";
import MenuListTableToolbar from "components/menu/ui/menuList/menuListTableToolbar";

interface MenuListTableProps {}

const MenuListTable: FC<MenuListTableProps> = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<MenuBasic[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [keywords, setKeywords] = useState<string | undefined>();

  /**
   * Get the first batch of menus
   */
  useEffect(() => {
    jspPlatformApi()
      .menu.searchMenuMenuMealSearchGet({
        keywords,
        numberPerPage: paginationModel.pageSize,
        pageIndex: paginationModel.page,
        returnKeysOnly: true,
      })
      .then((menuKeysResult) => {
        setRows(menuKeysResult.data);
        setRowCount(menuKeysResult.total_counts ?? -1);
      });
  }, [keywords, paginationModel.page, paginationModel.pageSize]);

  /**
   * Get the first batch of ingredient keys
   */
  useEffect(() => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 0 });
  }, [paginationModel.pageSize, keywords]);

  /**
   * Go to menu detail edit page
   * @param menuId The menu's ID to edit
   */
  const handleEditClick = (menuId: number) => () => {
    navigate(`${Routes.MENU_EDIT}/${menuId}`, {
      replace: false,
      state: { editingMenuId: menuId },
    });
  };

  /**
   * Menu list table column declaration
   */
  const columns: GridColDef<MenuBasic>[] = [
    {
      field: "editAction",
      type: "actions",
      width: 45,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: "id", headerName: "ID", width: 90, editable: false },
    { field: "name", headerName: "Name", flex: 180, editable: false },
  ];

  return (
    <DataGridListBox>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: MenuListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            setKeywords,
          },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        rowCount={rowCount}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: true,
              id: true,
            },
          },
        }}
      />
    </DataGridListBox>
  );
};

export default MenuListTable;
